<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-alert dense outlined colored-border border="left" type="info">
          Utilize as tags abaixo de cada mensagem para que ela seja
          autocompletada pelo sistema.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <span class="text-h6">Lembrete de cobrança Mensal</span>
        <v-textarea
          v-model="form.message_billing"
          outlined
          hide-details
          auto-grow
          class="mb-2"
        />
        <v-alert dense color="grey lighten-4">
          <v-row>
            <v-col>
              <small>
                {nome_{{ customer_alias }}}, {meu_contato}, {link_meu_contato},
                {nome_profissional}, {detalhes}, {valor}, {valor_extenso},
                {valor_sessao}, {valor_sessao_extenso},
              </small>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <span class="text-h6">Mensagem de cobrança</span>
        <v-textarea
          v-model="form.transaction_billing_message"
          outlined
          hide-details
          class="mb-2"
          auto-grow
        />
        <v-alert dense color="grey lighten-4">
          <v-row>
            <v-col>
              <small>
                {nome_{{ customer_alias }}}, {meu_contato},
                {link_meu_contato},{valor}, {valor_extenso}, {descricao},
                {nome_profissional}
              </small>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <span class="text-h6">Lembrete de Sessão</span>
        <v-textarea
          v-model="form.session_reminder_message"
          outlined
          hide-details
          class="mb-2"
          auto-grow
        />
        <v-alert dense color="grey lighten-4">
          <v-row>
            <v-col>
              <small>
                {data}, {hora}, {nome_profissional}, {nome_{{
                  customer_alias
                }}}, {meu_contato}, {link_meu_contato}
              </small>
            </v-col>
          </v-row>
        </v-alert>
        <v-switch
          v-model="form.session_reminder_enable_cancellation"
          label="Habilitar  cancelamento e reagendamento"
          :true-value="1"
          :false-value="0"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <span class="text-h6">Lembrete de Sessão Suspensa</span>
        <v-textarea
          v-model="form.suspended_message"
          outlined
          hide-details
          class="mb-2"
          auto-grow
        />
        <v-alert dense color="grey lighten-4">
          <v-row>
            <v-col>
              <small>
                {nome_{{ customer_alias }}}, {data}, {hora},
                {nome_profissional}, {meu_contato}, {link_meu_contato}
              </small>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end" cols="12" md="8" offset-md="2">
        <v-btn @click="save()" color="primary">Salvar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  data() {
    return {
      form: {
        message_billing: null,
        transaction_billing_message: null,
        session_reminder_message: null,
      },
    };
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },
    customer_alias() {
      return customerAlias().toLowerCase();
    },
  },

  mounted() {
    this.$loading.start();
    this.$auth
      .getLoginData()
      .then((response) => {
        this.form = JSON.parse(JSON.stringify(this.company));
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
    //
  },
  methods: {
    save() {
      this.$loading.start();
      this.$http
        .update("company/company", this.company.id, this.form)
        .then((response) => {
          this.$router.push("/configuracoes");
          this.$auth.getLoginData();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.v-alert--outlined
  border-color: #2196f3 !important
</style>
